import styled from "styled-components";

export const MenuWrapper = styled.div`
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    width: 100%;
    padding: 8px 16px;
    background-color: #fff;
    z-index: 999;
`;

export const MenuFlex = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
`;

export const MenuItem = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    text-decoration: none;

    & > img {
        width: 25px;
    }

    & > span {
        font-size: 13px;
        font-weight: 600;
    }

    &:visited, &:active {
       color: #222;
    }
`;