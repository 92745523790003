import React from "react";
import logo from '../../assets/images/coolkas.png';
import { getCurrentUser } from "../../services/authService";
import { NavbarAuth, NavbarContainer, NavbarWrapper } from "./style";

const user = getCurrentUser();

export const Navbar = () => {
    return (
        <NavbarWrapper>
            <NavbarContainer>
                <img src={logo} alt="logo" />
                <NavbarAuth>
                    <p>Hi, {user['full_name']}</p>
                </NavbarAuth>
            </NavbarContainer>
        </NavbarWrapper>
    )
}