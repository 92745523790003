import React from "react";
import { OrderDetailWrapper } from "../../../section/orders/style";
import { DataWrapper, FormWrapper } from "../../form/style";

export const OrderDetail = ({ order }) => {
    return (
        <FormWrapper>
            <h1>Order Detail {order['order_number']}</h1>
            <DataWrapper>
                {order['driver'] !== '' ? <OrderDetailWrapper>
                    <h1>Driver</h1>
                    <p>{order['driver']}</p>
                </OrderDetailWrapper> : null}
                {order['vehicle'] !== "" ?
                    <OrderDetailWrapper>
                        <h1>Kendaraan</h1>
                        <p>{order['vehicle']}</p>
                    </OrderDetailWrapper> : null}
                {order['updated_at'] ?
                    <OrderDetailWrapper>
                        <h1>Aktivitas Terakhir</h1>
                        <p>{new Date(order['updated_at']).toLocaleDateString("en-US", {
                            day: "2-digit",
                            month: "long",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "numeric"
                        })}</p>
                    </OrderDetailWrapper> : null}
                {order['file_url'] !== "" ?
                    <OrderDetailWrapper>
                        <h1>File Pendukung</h1>
                        <a rel="noreferrer" target="_blank" href={order['file_url']} style={{ textDecoration: "underline" }}>{order['file_url'].split("/")[6]}</a>
                    </OrderDetailWrapper> : null}
            </DataWrapper>
        </FormWrapper>
    )
}