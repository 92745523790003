import React from "react";
import { Navbar } from "../../components/navbar";
import { BottomMenu } from "../../section/menu";
import { TripSection } from "../../section/trip";
import { MainWrapper } from "../main";

export const Trip = () => {
    return (
        <MainWrapper>
            <Navbar />
            <TripSection />
            <BottomMenu />
        </MainWrapper>
    );
}