import React from "react";
import { MenuFlex, MenuItem, MenuWrapper } from "./style";

export const BottomMenu = () => {
    return (
        <MenuWrapper>
            <MenuFlex>
                <MenuItem href="/home">
                    <img alt="ho,e" src="https://assets.tokopedia.net/assets-tokopedia-lite/v2/poseidon/kratos/972f1d82.svg" />
                    <span>Home</span>
                </MenuItem>
                <MenuItem href="/trip">
                    <img alt="trip" src="https://assets.tokopedia.net/assets-tokopedia-lite/v2/poseidon/kratos/18f3fc93.svg" />
                    <span>Tripku</span>
                </MenuItem>
            </MenuFlex>
        </MenuWrapper>
    )
}