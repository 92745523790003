import styled from "styled-components";
import img from '../../assets/images/background.jpg';

export const AuthPageWrapper = styled.div`
    background: url(${img});
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
`;

export const AuthContainer = styled.div`
    height: 100vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const AuthContainerWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const AuthCard = styled.div`
    background-color: white;
    max-width: 83.33333333333334%;
    flex: 0 0 83.33333333333334%;
    border-radius: 12px;
    border: 1px solid #f0f0f0;
    padding: 20px;
`;

export const AuthHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;

    & > img {
        width: 125px;
    }

    & > p {
        color: #72849a;
        margin: 8px 0 1rem;
        font-size: 14px;
    }
`;