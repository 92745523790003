import React, { useState } from "react";
import { toast } from "react-toastify";
import { OrderButton } from "../../../section/orders/style";
import { getCurrentUser } from "../../../services/authService";
import { uploadImage } from "../../../services/gcloudService";
import { updateOutbound } from "../../../services/orderService";
import { DataInputWrapper, FormWrapper } from "../style";

const user = getCurrentUser();

export const UploadFile = ({ order }) => {
  const [data, setData] = useState({});
  const [files, setFiles] = useState([]);
  const [fileURLs, setFileURLs] = useState([]);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (data["file"] === "") {
      toast.error("File masih kosong!");
      return;
    }
    try {
      const urls = [];
      for (const file of files) {
        const { status, data } = await uploadImage(file, "umara", file.name);
        if (status === 200) {
          const fileURL = data.data["pathname"];
          urls.push(fileURL);
        }
      }

      data["driver"] = user["username"];
      data["file_url"] = urls[0];
      const { status } = await updateOutbound(order["id"], data);
      if (status === 200) {
        toast.success("Terimakasih!");
        setTimeout(() => {
          window.location = "/home";
        }, 800);
      }
    } catch (e) {}
  };

  const onFileChange = (e) => {
    let currFiles = [...files];
    let objectURLs = [...fileURLs];
    const currData = { ...data };
    for (let i = 0; i < e.target.files.length; i++) {
      currData[e.target.name] = URL.createObjectURL(e.target.files[i]);
      currFiles.push(e.target.files[i]);
      objectURLs.push(URL.createObjectURL(e.target.files[i]));
    }

    setData(currData);
    setFileURLs(objectURLs);
    setFiles(currFiles);
  };

  return (
    <FormWrapper>
      <form onSubmit={onSubmit}>
        <DataInputWrapper>
          <label>File Pendukung</label>
          <input
            type="file"
            style={{
              border: "0",
              padding: "0",
              borderRadius: "0",
              marginTop: "6px",
            }}
            onChange={onFileChange}
          />
        </DataInputWrapper>
        <OrderButton>Upload</OrderButton>
      </form>
    </FormWrapper>
  );
};
