import { getAuthHeadersWithMultipart } from "./authService";
import httpService from "./httpService";

export async function uploadImage(file, bucketPath, filename = "") {
    const formData = new FormData();
    formData.append("file", file);
    return httpService.post(
        "/driver/upload",
        formData,
        getAuthHeadersWithMultipart(bucketPath, filename)
    );
}