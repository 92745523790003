import styled from "styled-components";

export const CardFlex = styled.div`
    display: flex;
    gap: 10px;
    margin-top: 1rem;
`;

export const Card = styled.div`
    background-color: #fff;
    border-radius: 12px;
    padding: 16px;
    box-shadow: 0px 4px 40px rgba(120, 110, 185, 0.11);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 18px;
    width: 100%;
`;

export const CardDetail = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;

    & > h1 {
        font-size: 18px;
        font-weight: 800;
        margin: 0;
    }

    & > span {
        font-size: 14px;
        font-weight: 600;
    }
`;