import React from "react";
import { toast } from "react-toastify";
import { OrderButton } from "../../../section/orders/style";
import { getCurrentUser } from "../../../services/authService";
import { updateOutbound } from "../../../services/orderService";
import { DataInputWrapper, FormWrapper } from "../style";

const user = getCurrentUser();

// const listFilter = [
//     {
//         value: "B 9275 SWX",
//         label: "B 9275 SWX",
//     },
//     {
//         value: "B 9185 SWX",
//         label: "B 9185 SWX",
//     },
//     {
//         value: "B 9187 SWX",
//         label: "B 9187 SWX",
//     },
//     {
//         value: "B 9273 SWX",
//         label: "B 9273 SWX",
//     },
//     {
//         value: "B 9313 SWX",
//         label: "B 9313 SWX",
//     },
//     {
//         value: "B 9315 SWX",
//         label: "B 9315 SWX",
//     },
//     {
//         value: "B 9307 SWX",
//         label: "B 9307 SWX",
//     },
//     {
//         value: "B 9189 SWX",
//         label: "B 9189 SWX",
//     },
//     {
//         value: "B 9325 SWX",
//         label: "B 9325 SWX",
//     },
//     {
//         value: "B 9323 SWX",
//         label: "B 9323 SWX",
//     },
//     {
//         value: "B 9324 SWX",
//         label: "B 9324 SWX",
//     },
//     {
//         value: "B 9312 SWX",
//         label: "B 9312 SWX",
//     },
//     {
//         value: "B 9308 SWX",
//         label: "B 9308 SWX",
//     },
//     {
//         value: "B 9186 SWX",
//         label: "B 9186 SWX",
//     },
//     {
//         value: "B 9296 SWX",
//         label: "B 9296 SWX",
//     },
//     {
//         value: "B 9294 SWX",
//         label: "B 9294 SWX",
//     },
//     {
//         value: "B 9298 SWX",
//         label: "B 9298 SWX",
//     },
//     {
//         value: "B 9314 SWX",
//         label: "B 9314 SWX",
//     },
//     {
//         value: "B 9188 SWX",
//         label: "B 9188 SWX",
//     },
//     {
//         value: "B 9685 FCH",
//         label: "B 9685 FCH",
//     },
//     {
//         value: "B 9495 TCL",
//         label: "B 9495 TCL",
//     },
//     {
//         value: "B 9365 TCP",
//         label: "B 9365 TCP",
//     },
//     {
//         value: "B 9367 TCP",
//         label: "B 9367 TCP",
//     },
//     {
//         value: "B 9578 PCN",
//         label: "B 9578 PCN",
//     },
//     {
//         value: "B 9622 FCH",
//         label: "B 9622 FCH",
//     },
//     {
//         value: "B 9473 TCN",
//         label: "B 9473 TCN",
//     },
//     {
//         value: "B 9486 SRU",
//         label: "B 9486 SRU",
//     },
//     {
//         value: "B 9756 UXV",
//         label: "B 9756 UXV",
//     }
// ];

export const TakeOrderForm = ({ order }) => {
    const onSubmit = async (e) => {
        e.preventDefault();

        try {
            order["status"] = "Dalam Pengiriman";
            const { status } = await updateOutbound(order["id"], order);
            if (status === 200) {
                toast.success("Hati-hati di jalan!");
                setTimeout(() => {
                    window.location = "/home";
                }, 800);
            }
        } catch (e) { }
    };

    return (
        <FormWrapper>
            <form onSubmit={onSubmit}>
                <DataInputWrapper>
                    <label>Driver</label>
                    <input type="text" disabled value={user["username"]} />
                </DataInputWrapper>
                <DataInputWrapper>
                    <label>Kendaraan*</label>
                    <input type="text" disabled value={order["vehicle"]} />
                </DataInputWrapper>
                <OrderButton>Berangkat</OrderButton>
            </form>
        </FormWrapper>
    );
};
