import styled from "styled-components";

export const NavbarWrapper = styled.nav`
    box-shadow: 0px 4px 40px rgba(120, 110, 185, 0.11);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 10px 15px;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
    background-color: #fff;
    z-index: 999;
`;

export const NavbarContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > img {
        width: 75px;
    }
`;

export const NavbarAuth = styled.div`
    & > p {
        font-size: 14px;
    }
`;