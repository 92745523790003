import React, { useState } from "react";
import { toast } from "react-toastify";
import { login } from "../../../services/authService";
import { AuthButton, AuthFormWrapper, FormDivider, FormInput, FormInputSVGWrapper, FormLabel } from "./style";

export const AuthForm = () => {
    const [data, setData] = useState({});

    const onSubmit = async (e) => {
        e.preventDefault();
        if (data['identity'] === '') {
            toast.error("Email tidak boleh kosong")
            return
        }

        if (data['password'] === '') {
            toast.error("Kata Sandi tidak boleh kosong")
            return
        }

        try {
            const { status, data: resp } = await login(data);
            if (status === 200) {
                const token = resp.data['token'];
                localStorage.setItem("token", token);
                console.log(token);
                setTimeout(() => {
                    window.location = "/home"
                }, 700)
            }
        } catch (e) {
            if (e.response.status && e.response.status >= 400) {
                toast.error(e.response.data.message)
            }
        }
    }

    const onChange = ({ currentTarget: input }) => {
        data[input.name] = input.value;
        setData(data);
    }

    return (
        <AuthFormWrapper>
            <form onSubmit={onSubmit}>
                <FormDivider>
                    <FormLabel>
                        Email
                    </FormLabel>
                    <FormInput>
                        <FormInputSVGWrapper>
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="mail" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-40 110.8V792H136V270.8l-27.6-21.5 39.3-50.5 42.8 33.3h643.1l42.8-33.3 39.3 50.5-27.7 21.5zM833.6 232L512 482 190.4 232l-42.8-33.3-39.3 50.5 27.6 21.5 341.6 265.6a55.99 55.99 0 0068.7 0L888 270.8l27.6-21.5-39.3-50.5-42.7 33.2z"></path></svg>
                        </FormInputSVGWrapper>
                        <input type="text" name="identity" onChange={onChange} />
                    </FormInput>
                </FormDivider>
                <FormDivider>
                    <FormLabel>
                        Kata Sandi
                    </FormLabel>
                    <FormInput>
                        <FormInputSVGWrapper>
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="lock" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M832 464h-68V240c0-70.7-57.3-128-128-128H388c-70.7 0-128 57.3-128 128v224h-68c-17.7 0-32 14.3-32 32v384c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V496c0-17.7-14.3-32-32-32zM332 240c0-30.9 25.1-56 56-56h248c30.9 0 56 25.1 56 56v224H332V240zm460 600H232V536h560v304zM484 701v53c0 4.4 3.6 8 8 8h40c4.4 0 8-3.6 8-8v-53a48.01 48.01 0 10-56 0z"></path></svg>                        </FormInputSVGWrapper>
                        <input type="password" name="password" onChange={onChange} />
                    </FormInput>
                </FormDivider>
                <AuthButton>
                    Sign In
                </AuthButton>
            </form>
        </AuthFormWrapper>
    )
}