import "bootstrap/dist/css/bootstrap.css";
import { RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './App.css';
import { DataProvider } from './components/context';
import { AuthPage } from './pages/auth';
import { MainPage } from './pages/main';
import { Trip } from "./pages/trip";
import { getCurrentUser } from './services/authService';

const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthPage />,
    errorElement: <div><h1>Page Not Found</h1></div>,
    // loader: () => {
    //   const user = getCurrentUser();
    //   if (user) {
    //     return redirect("/home");
    //   }
    //   return null;
    // }
  },
  {
    path: "/home",
    element: <MainPage />,
    loader: () => {
      const user = getCurrentUser();
      if (!user || user['role'] !== "Driver") {
        return redirect("/");
      }
      return null;
    }
  },
  {
    path: "/trip",
    element: <Trip />,
    loader: () => {
      const user = getCurrentUser();
      if (!user || user['role'] !== "Driver") {
        return redirect("/");
      }
      return null;
    }
  }
])

function App() {
  return (
    <DataProvider>
      <ToastContainer />
      <RouterProvider router={router} />
    </DataProvider>
  );
}

export default App;
