import React, { useState } from "react";

export const DataContext = React.createContext();

export const DataProvider = ({ children }) => {
    const [email, setEmail] = useState("");
    return (
        <DataContext.Provider value={{ email, setEmail }}>
            {children}
        </DataContext.Provider>
    )
}