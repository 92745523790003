import styled, { keyframes } from "styled-components";

export const SectionWrapper = styled.div`
    padding: 1rem 15px;
    margin-top: 3rem;

    & > h1 {
        font-size: 24px;
        color: #3c4145;
        font-weight: 600;
    }
`;

export const OrderList = styled.div`
    display: grid;
    margin-top: 1.5rem;
    gap: 1rem;
`;

export const OrderCard = styled.div`
    box-shadow: 3px 4px 0 0 #fee156;
    border-radius: 8px;
    padding: 1rem;
    border: 2px solid rgb(17 24 39);
`;

export const OrderCardHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > span {
        font-size: 14px;
    }
`;

export const OrderStatusWrapper = styled.div`
    background: ${({ color }) => color};
    border-radius: 4px;
    border: 0;
    padding: 5px 8px;
    position: relative;
    color: #333;
    font-weight: 800;
    font-size: 14px;
`;

export const OrderDetailWrapper = styled.div`
    margin: 8px 0;

    & > h1 {
        font-size: 14px;
        margin-bottom: 2px;
        font-weight: 600;
    }

    & > p, & > a {
        font-size: 14px;
    }
`;

export const OrderButtonFlex = styled.div`
    display: flex;
    gap: 8px;
`;

export const OrderButton = styled.button`
    box-shadow: 0 3px 0 1px #333, 0 0 0 1px #333;
    border-radius: 8px;
    color: #333;
    padding: 8px 14px;
    border: 0;
    background-color: #fff;
    margin-top: 12px;
    font-size: 14px;
`;

export const SearchBar = styled.div`
    display: flex;
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid rgb(208 209 211);
    margin-top: 1rem;

    & > input {
        width: 100%;
        border: 0;
        font-size: 14px;

        &:focus {
            outline: none;
        }
    }
`;

export const SearchIcon = styled.div`
    height: 1.5rem;
    width: 1.5rem;
    background-color: #3e79f7;
    border-radius: 4px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledTable = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  margin-top: 12px;

  th,
  td {
    //border: 1px solid #ddd;
    padding: .8rem;
    text-align: left;
  }

  td {
    position: relative;
    font-weight: 400;
    color: #545965;
  }

  input {
    max-width: 100%;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #ccc;
    padding: 4px;
  }

  tr:last-child {
    border: 0;
  }

  //tr:nth-child(even) {
  //  background-color: #f2f2f2;
  //}

  tr {
    border-bottom: 1px solid #eff0f2;
  }

  th {
    background-color: #f8f9fa;
    color: #545965;
    font-weight: 500;
    font-size: 0.925rem;
  }

  td {
    font-size: 0.9rem;
    color: #000;
  }
`;

export const ItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const ItemCard = styled.div`
    box-shadow: 0 0 #0000, 0 0 #0000,2px 3px 0px #000;
    border: 1px solid rgb(26 26 26);
    padding: 8px 12px;
    border-radius: 4px;

    & > h1 {
        font-size: 14px;
        margin: 0;
    }

    & > h2 {
        background-color: #3e79f7;
        padding: 4px 8px;
        border-radius: 4px;
        display: inline-block;
        font-size: 12px;
        color: #fff;
        margin-bottom: 6px;
    }

    & > p, span {
        font-size: 13px;
        margin: 4px 0 2px; 
    }
`;

export const ProgressBarStripes = keyframes`
    0% {
        background-position: 40px 0;
    }
    100% {
        background-position: 0 0;
    }
`

export const ProgressBar = styled.div`
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
    background-color: #3e79f7;
    animation: 1s linear infinite ${ProgressBarStripes};
    width: ${({ width }) => `${width}%`};
    height: 8px;
    margin-top: 6px;
    max-width: 100%;
`;