import React, { useEffect, useState } from "react";
import { FinishOrder } from "../../components/form/finishOrder";
import { TakeOrderForm } from "../../components/form/order";
import { ModalComponent } from "../../components/modal";
import { OrderDetail } from "../../components/modal/orderDetail";
import { getOutboundOrders } from "../../services/orderService";
import { getColorStatus } from "../../utils/utils";
import { ItemCard, ItemWrapper, OrderButton, OrderButtonFlex, OrderCard, OrderCardHeader, OrderDetailWrapper, OrderList, OrderStatusWrapper, ProgressBar, SearchBar, SearchIcon, SectionWrapper } from "./style";

export const OrderSection = () => {
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [checkItems, setCheckItems] = useState(false);
    const [takeOrder, setTakeOrder] = useState(false);
    const [finishOrder, setFinishOrder] = useState(false);
    const [detailOrder, setDetailOrder] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState({});

    const fetchOrders = async () => {
        try {
            const { status, data } = await getOutboundOrders();
            if (status === 200) {
                setOrders(data.data);
                setFilteredOrders(data.data);
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        fetchOrders();
    }, []);

    const onChange = ({ currentTarget: input }) => {
        if (input.value === "") {
            setFilteredOrders(orders);
            return;
        }
        const filtered = orders.filter(order => order['order_number'].includes(input.value) || order['so_number'].includes(input.value));
        setFilteredOrders(filtered);
    }

    return (
        <SectionWrapper>
            <h1>Outbound<br />Order</h1>
            <SearchBar>
                <input type="text" placeholder="Cari Order Berdasarkan SO atau Order Number ..." onChange={onChange} />
                <SearchIcon>
                    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-[17.5px] text-neutral-0"><path d="M17.0793 16.5878L14.7054 14.2209C15.4713 13.2452 15.8869 12.0402 15.8854 10.7998C15.8854 9.69509 15.5578 8.61519 14.944 7.69666C14.3303 6.77813 13.458 6.06222 12.4373 5.63947C11.4167 5.21671 10.2937 5.1061 9.21018 5.32162C8.1267 5.53714 7.13146 6.06911 6.35031 6.85025C5.56917 7.6314 5.0372 8.62664 4.82168 9.71012C4.60616 10.7936 4.71678 11.9167 5.13953 12.9373C5.56228 13.9579 6.27819 14.8302 7.19672 15.444C8.11525 16.0577 9.19515 16.3853 10.2999 16.3853C11.5403 16.3868 12.7452 15.9713 13.721 15.2054L16.0878 17.5792C16.1527 17.6446 16.23 17.6966 16.315 17.732C16.4001 17.7675 16.4914 17.7857 16.5835 17.7857C16.6757 17.7857 16.767 17.7675 16.8521 17.732C16.9371 17.6966 17.0144 17.6446 17.0793 17.5792C17.1447 17.5143 17.1966 17.4371 17.2321 17.352C17.2675 17.2669 17.2858 17.1757 17.2858 17.0835C17.2858 16.9913 17.2675 16.9001 17.2321 16.815C17.1966 16.7299 17.1447 16.6527 17.0793 16.5878ZM6.11073 10.7998C6.11073 9.97127 6.35642 9.16134 6.81673 8.47245C7.27704 7.78355 7.93129 7.24662 8.69675 6.92955C9.46221 6.61249 10.3045 6.52953 11.1171 6.69117C11.9297 6.8528 12.6762 7.25178 13.262 7.83764C13.8479 8.4235 14.2469 9.16993 14.4085 9.98254C14.5701 10.7952 14.4872 11.6374 14.1701 12.4029C13.853 13.1684 13.3161 13.8226 12.6272 14.2829C11.9383 14.7432 11.1284 14.9889 10.2999 14.9889C9.18883 14.9889 8.12331 14.5476 7.3377 13.762C6.55209 12.9763 6.11073 11.9108 6.11073 10.7998Z" fill="currentColor"></path></svg>
                </SearchIcon>
            </SearchBar>
            <OrderList>
                {filteredOrders.map((order, index) => <OrderCard key={index}>
                    <OrderCardHeader>
                        <span><b>#</b>{order['order_number']}</span>
                        <OrderStatusWrapper color={getColorStatus(order['status'])}>
                            {order['status']}
                        </OrderStatusWrapper>
                    </OrderCardHeader>
                    <OrderDetailWrapper>
                        <h1>SO Number</h1>
                        <p>{order['so_number']}</p>
                    </OrderDetailWrapper>
                    <OrderDetailWrapper>
                        <h1>PO Number</h1>
                        <p>{order['po_number']}</p>
                    </OrderDetailWrapper>
                    <OrderDetailWrapper>
                        <h1>Customer ({order['customer_code']})</h1>
                        <p>{order['customer_name']}</p>
                    </OrderDetailWrapper>
                    {order['status'] === "Dalam Pengiriman" ?
                        <OrderDetailWrapper>
                            <h1>Progress</h1>
                            <ProgressBar width={(new Date() - new Date(order['updated_at'])) / 100000} />
                        </OrderDetailWrapper> : null}
                    <OrderButtonFlex>
                        {order['driver'] !== '' ? <OrderButton onClick={() => {
                            setDetailOrder(true);
                            setSelectedOrder(order);
                        }}>
                            Detail Order
                        </OrderButton> : null}
                        {order["status"] === "Dalam Pengiriman" ? <OrderButton onClick={() => {
                            setFinishOrder(true);
                            setSelectedOrder(order);
                        }}>
                            Selesaikan Order
                        </OrderButton> : order["status"] === "Proses Outbound" ? <OrderButton onClick={() => {
                            setTakeOrder(true);
                            setSelectedOrder(order);
                        }}>
                            Ambil Order
                        </OrderButton> : null}
                        <OrderButton onClick={() => {
                            setCheckItems(true);
                            setSelectedOrder(order);
                        }}>
                            Cek Barang
                        </OrderButton>
                    </OrderButtonFlex>
                </OrderCard>)}
            </OrderList>
            {takeOrder && <ModalComponent
                show={takeOrder}
                onHide={() => setTakeOrder(!takeOrder)}
                body={
                    <TakeOrderForm order={selectedOrder} />
                }
            />}
            {finishOrder && <ModalComponent
                show={finishOrder}
                onHide={() => setFinishOrder(!finishOrder)}
                body={
                    <FinishOrder order={selectedOrder} />
                }
            />}
            {checkItems && <ModalComponent
                show={checkItems}
                onHide={() => setCheckItems(!checkItems)}
                body={<ItemWrapper>
                    {selectedOrder['items'].map((item, index) => <ItemCard key={index}>
                        <h2>{item['material_number']}</h2>
                        <h1>{item['product_name']}</h1>
                        <p>Quantity: {item['quantity']}</p>
                        <span>Expiry Date: {new Date(item['expiry_date']).toLocaleDateString('en-US', {
                            day: "2-digit",
                            month: "long",
                            year: "numeric"
                        })}</span>
                    </ItemCard>)}
                </ItemWrapper>}
            />}
            {detailOrder && <ModalComponent
                show={detailOrder}
                onHide={() => setDetailOrder(!detailOrder)}
                body={<OrderDetail order={selectedOrder} />}
            />}
        </SectionWrapper>
    )
}