import React from "react";
import logo from '../../assets/images/coolkas.png';
import { AuthForm } from "./authform";
import { AuthCard, AuthContainer, AuthContainerWrapper, AuthHeader, AuthPageWrapper } from "./style";

export const AuthPage = () => {
    return (
        <AuthPageWrapper>
            <AuthContainer>
                <AuthContainerWrapper>
                    <AuthCard>
                        <AuthHeader>
                            <img src={logo} alt="logo" />
                            <p>
                                Driver Management System
                            </p>
                        </AuthHeader>
                        <AuthForm />
                    </AuthCard>
                </AuthContainerWrapper>
            </AuthContainer>
        </AuthPageWrapper>
    )
}