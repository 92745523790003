import styled from "styled-components";

export const AuthFormWrapper = styled.div`
    margin: 3rem 0 1rem;
`;

export const FormDivider = styled.div`
    margin: 6px 0 18px;
`;

export const FormLabel = styled.label`
    color: #455560;
    font-size: 14px;
    font-weight:500;

    &::before{
        color: #ff6b72;
        content: "*";
        margin-inline-end: 4px;
    }
`;

export const FormInput = styled.div`
    background-color: #fff;
    padding: 10px 11px;
    border-radius: 10px;
    font-size: 14px;
    color: #455560;
    display: flex;
    align-items: center;
    border: 1px solid #e6ebf1;
    margin-top: 6px;
    gap: 8px;

    & > input {
        width: 100%;
        border: 0;
        font-size: 14px;

        &:focus {
            outline: none;
        }
    }
`;

export const FormInputSVGWrapper = styled.span`
    color: #3e79f7!important;
    display: flex;
    align-items: center;
`;

export const AuthButton = styled.button`
    background-color: #3e79f7;
    box-shadow: 0 2px 0 rgba(5, 122, 255, 0.06);
    color: #fff;
    height: 40px;
    padding: 8px 15px;
    border-radius: 10px;
    font-size: 14px;
    outline: none;
    border: 0;
    width: 100%;
    margin-top: 6px;
`;