import React from "react";
import styled from "styled-components";
import { Navbar } from "../../components/navbar";
import { BottomMenu } from "../../section/menu";
import { OrderSection } from "../../section/orders";

export const MainWrapper = styled.div`
    position: relative;
    padding-bottom: 4rem;
`;

export const MainPage = () => {
    return (
        <MainWrapper>
            <Navbar />
            <OrderSection />
            <BottomMenu />
        </MainWrapper>
    )
}