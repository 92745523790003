import styled from "styled-components";

export const FormWrapper = styled.div`
    padding: 1rem;

    & > form {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    & > h1 {
        font-size: 16px;
        font-weight: 700;
    }
`;

export const DataInputWrapper = styled.div`
    & > label {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 6px;
    }
    & > input, & > textarea, & > select {
        width: 100%;
        border: 0;
        font-size: 14px;
        padding: 8px 12px;
        border-radius: 8px;
        border: 1px solid rgb(208 209 211);

        &:focus {
            outline: none;
        }

        &:disabled {
            background-color: #EBEBE4;
        }
    }
`;

export const DataWrapper = styled.div`
    margin-top: 1rem;
`;

export const selectStyles = {
    control: (provided, state) => ({
        ...provided,
        background: "#fff",
        borderColor: "#ddd",
        minHeight: "40px",
        height: "40px",
        fontSize: "13px",
        width: "100%",
        boxShadow: state.isFocused ? null : null,
    }),
    option: (provided) => ({
        ...provided,
        fontSize: "13px",
    })
};